import QRCode from 'qrcode-svg';

export default class QrCode {

    constructor($element) {
        this.$element = $element;

        this.initialize();
    }

    initialize() {
        const code = new QRCode({
            content: this.getUrl(),
            join: true,
            padding: 3,
            xmlDeclaration: false,
            container: 'svg-viewbox',
        });

        this.$element.innerHTML = code.svg();
    }

    getUrl() {
        return this.$element.dataset.qrCodeUrl || window.location.href;
    }

}
