/*
 * Takes a URL and adds the values of common analytics
 * parameters (like `utm_medium` etc.) of the current page.
 */
export const addAnalyticsParametersToUrl = (url, params = {}) => {
	const keys = ['utm_source', 'utm_medium', 'utm_campaign'];
	const currentUrl = new URL(window.location.href);
	url = new URL(url);

	keys.forEach(key => {
	    const value = currentUrl.searchParams.get(key) || params[key];
	    if(!value) return;

	    url.searchParams.set(key, encodeURIComponent(value));
	});

	return url;
};