import Banner from './banner.js';

const template = translation => {
    let message = translation.message.replace(/\{(.+)\}/, (match, text) => {
        return `<a href="${translation.url}">${ text }</a>`;
    });

    return `
        <div
            class="c-notification-bar c-notification-bar--center"
            lang="${translation.locale.substr(0, 2)}"
        >
            <div class="c-notification-bar__message">${message}</div>
        </div>
    `;
};

export default class LanguageBanner {

    constructor($element, data = {}) {
        this.banner = new Banner('language-banner', $element, {

            isDismissable: false,

            render() {
                const userLanguage = navigator.language.replace('-', '_');

                const translation = Object.values(data).find(({ locale }) => {
                    const matchesLocale = locale === userLanguage;
                    const matchesLanguage = locale.substr(0, 2) === userLanguage;

                    return matchesLocale || matchesLanguage;
                });

                if(!translation) {
                    return;
                }

                return template(translation);
            },

        });
    }

};
