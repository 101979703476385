import createFocusTrap from 'focus-trap';
import { detectMobileDevice } from '../lib/device-detection.js';

export default class ExitPopup {

    constructor(element = null) {
        this.element = element;
        this.id = this.element.id;
        this.storageKey = 'exit-popup';
        this.dialog = this.element.querySelector('.c-exit-popup__dialog');
        this.closeElement = this.element.querySelector('.c-exit-popup__close');
        this.mobileDelay = this.element.dataset.exitPopupMobileDelay || 45;
        this.mobileDelay = parseInt(this.mobileDelay, 10);

        this.addEventListeners();

        this.focusTrap = createFocusTrap(this.dialog, {
            initialFocus: this.closeElement,
        });

        this.openOnExitIntent();
    }

    addEventListeners() {
        this.closeElement.addEventListener('click', event => {
            event.preventDefault();
            this.close();
        })

        window.addEventListener('keydown', event => {
            if(event.key !== 'Escape' && event.key !== 'Esc') {
                return;
            }

            this.close();
        });
    }

    isOpen() {
        return this.element.getAttribute('aria-hidden') === 'false';
    }

    isDismissed() {
        return localStorage.getItem(this.storageKey) === 'DISMISSED';
    }

    open() {
        if(this.isOpen()) {
            return;
        }

        this.element.setAttribute('aria-hidden', 'false');
        this.focusTrap.activate();
        document.body.style.overflow = 'hidden';
    }

    close() {
        if(!this.isOpen()) {
            return;
        }

        this.element.setAttribute('aria-hidden', 'true');
        this.focusTrap.deactivate();
        document.body.style.overflow = null;
        localStorage.setItem(this.storageKey, 'DISMISSED');
    }

    openOnExitIntent() {
        const isMobile = detectMobileDevice() !== false;

        const open = () => {
            if (!this.isDismissed() && !this.hasVisibleCookieDialogs()) {
                this.open();
            }
        };

        document.documentElement.addEventListener('mouseleave', (event) => {
            const padding = 50;

            if (event.clientY < (window.innerHeight - padding)) {
                open();
            }
        });

        if (isMobile) {
            setTimeout(open, this.mobileDelay * 1000);
        }
    }

    hasVisibleCookieDialogs() {
        // This is a workaround to check that neither the initial CookieHub dialog
        // nor the settings dialog is open.
        const dialogs = Array.from(document.querySelectorAll('.ch2-dialog, .ch2-settings'));

        return dialogs.some((dialog) => {
            const style = getComputedStyle(dialog);
            return style.visibility !== 'hidden' && style.display !== 'none';
        });
    }

}
