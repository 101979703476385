const TRANSITION_DURATION = 250;
const SCROLL_THRESHOLD = 0.05;

export default class FloatingCta {

    constructor(element) {
        this.element = element;

        const scrollHandler = () => {
            requestAnimationFrame(() => {
                if (window.scrollY < window.innerHeight * SCROLL_THRESHOLD) {
                    return;
                }

                this.show();
                window.removeEventListener('scroll', scrollHandler);
            });
        };

        window.addEventListener('scroll', scrollHandler);
    }

    show() {
        this.element.style.display = 'block';
        this.element.style.transform = 'translateY(300%)';

        const animation = this.element.animate({
            transform: 'translateY(0)',
        }, {
            duration: TRANSITION_DURATION,
            easing: 'cubic-bezier(.165, .84, .44, 1)',
            fill: 'forwards',
        });
    }

}
