const DEFAULTS = {
    isDismissable: true,
    dismissClass: 'is-dismissed',
    storageLifetime: 2 * 24 * 365,
    renewStorage: true,
    prerendered: false,
};

const DISMISSED = 'DISMISSED';

export default class Banner {

    constructor(name = 'banner', $element, options = {}) {
        this.name = name;
        this.$element = $element;
        this.options  = Object.assign({}, DEFAULTS, options);

        this.init();
    }

    getStorageKey() {
        return `${this.name}_state`;
    }

    init() {
        if(this.options.renewStorage) {
            this.renewStorage();
        }

        if(
            !this.options.forceShow &&
            this.isDismissable() &&
            this.isDismissed()
        ) {
            return;
        }

        const html = this.render();

        if(!html && !this.options.prerendered) {
            return;
        }

        this.mount(html);
    }

    render() {
        if(this.options.render) {
            return this.options.render(this);
        }
    }

    mount(html) {
        if(html) {
            let $temp = document.createElement('div');
            $temp.innerHTML = html;
            if($temp.children.length === 0) return;

            let $banner = $temp.children[0];
            this.$element.parentNode.insertBefore($banner, this.$element);

            this.$element.parentNode.removeChild(this.$element);
            this.$element = $banner;
        }

        this.addStatusClass();
        this.onmount();
    }

    onmount() {
        const dismissLinks = this.$element.querySelectorAll('[data-banner-dismiss]');

        dismissLinks.forEach(link => {
            link.addEventListener('click', this.dismissClickHandler.bind(this));
        });

        if(this.options.onmount) {
            this.options.onmount(this);
        }
    }

    dismissClickHandler(event) {
        event.preventDefault();
        this.dismiss();
    }

    dismiss() {
        if(!this.isDismissable()) return;

        this.setDismissed();
        this.$element.classList.add(this.options.dismissClass);

        this.removeStatusClass();
        this.ondismiss();
    }

    ondismiss() {
        if(this.options.ondismiss) {
            this.options.ondismiss(this);
        }
    }

    isDismissed() {
        if(!this.isDismissable()) {
            return false;
        }

        const data = localStorage.getItem(this.getStorageKey());
        const { state, timestamp } = JSON.parse(data) || {};

        if(state !== DISMISSED) {
            return false;
        }

        if(timestamp < (Date.now() - this.options.storageLifetime * 24 * 60 * 60 * 1000)) {
            return false;
        }

        return true;
    }

    setDismissed() {
        if(!this.isDismissable()) return;

        localStorage.setItem(
            this.getStorageKey(),
            JSON.stringify({
                state: DISMISSED,
                timestamp: Date.now(),
            })
        );
    }

    unsetDismissed() {
        localStorage.removeItem(this.getStorageKey());
    }

    isDismissable() {
        return this.options.isDismissable === true;
    }

    renewStorage() {
        if(this.isDismissed()) {
            this.setDismissed();
        }
    }

    addStatusClass() {
        document.documentElement.classList.add(`has-${ this.name }`);
    }

    removeStatusClass() {
        document.documentElement.classList.remove(`has-${ this.name }`);
    }

};