/**
 *
 * Project: MyTherapy App Marketing Website
 * Main JavaScript Bundle
 * 
 */

import LanguageBanner from './components/language-banner.js';
import FloatingCta from './components/floating-cta.js';
import ExitPopup from './components/exit-popup.js';
import QrCode from './components/qr-code.js';
import CookieHub from './components/cookie-hub.js';
import TrackingService from './privacy/tracking-service.js';
import { addAnalyticsParametersToUrl } from './lib/analytics.js';

/*
 * Language Banner
 */
setTimeout(() => {
    const $element = document.querySelector('[data-language-banner]');
    if(!$element) return;

    const data = JSON.parse($element.dataset.languageBanner);
    const languageBanner = new LanguageBanner($element, data);
}, 0);

/*
 * Floating CTA
 */

setTimeout(() => {
    const $element = document.querySelector('[data-floating-cta]');
    if(!$element) return;
    new FloatingCta($element);
}, 0);

/*
 * Exit popup
 */
setTimeout(() => {
    const $element = document.querySelector('[data-exit-popup]');

    if(!$element) {
        return;
    }

    new ExitPopup($element);
});

/**
 * Dynamically generate QRCode
 */

setTimeout(() => {
    document.querySelectorAll('.c-qr-code').forEach(element => {
        new QrCode(element);
    });
}, 0);

const GOOGLE_TAG_MANAGER_ID = 'GTM-59NC527';
const COOKIEHUB_ID = '8c5ceffa';

/*
 * Set up Google Tag Manager, based on the GTM docs:
 * https://developers.google.com/tag-manager/quickstart
 */
const tagManager = new TrackingService(
    'tagManager',
    'marketing',
    [
        () => {
            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
        },
        `https://www.googletagmanager.com/gtm.js?id=${GOOGLE_TAG_MANAGER_ID}`,
    ],
);

/*
 * CookieHub configuration
 */
const services = [ tagManager ];
const currentPath = document.documentElement.dataset.path;

const cookieHub = new CookieHub(COOKIEHUB_ID, services, {
    hideDialog: currentPath.endsWith('legal-information'),
});

cookieHub.init();

/*
 * Pass on Google Analytics parameters to App stores
 */
setTimeout(() => {
    const elements = document.querySelectorAll('[data-store-link]');
    
    Array.from(elements).forEach($element => {
        $element.href = addAnalyticsParametersToUrl($element.href, {
            'utm_source': $element.dataset.storeLinkSource,
            'utm_medium': $element.dataset.storeLinkMedium,
            'utm_campaign': $element.dataset.storeLinkCampaign,
        });
    });
}, 0);
