/*
 * Parses the user agent string in order to detect
 * iOS and Android mobile devices
 */
export const detectMobileDevice = () => {
    const rules = {
        ios: /(iPhone|iPad|iPod)/,
        android: /Android/,
    };

    // iPadOS' Safari UA is the same as for desktop
    // macOS – only difference is the touch screen
    if(navigator.platform.includes('MacIntel') && 'ontouchend' in document) {
        return 'ios';
    }

    for(let os in rules) {
        let regex = rules[os];
        if(regex.test(navigator.userAgent)) {
            return os;
        }
    }

    return false;
};